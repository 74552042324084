<template>
    <div class="container">
        <div class="title">您访问的页面没有找到</div>
        <div class="button" @click="goBackPage">返回首页</div>
    </div>
</template>

<script>
export default {
    methods: {
        goBackPage() {
            this.$routerReplace('/')
        }
    }
}
</script>

<style lang="scss" scoped>
.container {
    padding: 20vw 10vw;
    text-align: center;
    height: 100vh;
    box-sizing: border-box;
}
.title {
    font-size: 6vw;
    font-weight: bold;
    color: #666;
}
.button {
    width: 40vw;
    text-align: center;
    border: 1px solid #ccc;
    color: #666;
    margin: 20vw auto;
    padding: 15px 20px;
    border-radius: 4px;
    box-sizing: border-box;
}
</style>